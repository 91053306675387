<template>
  <!-- 新增渠道商 -->
  <div class="container">
    <a-page-header :title="title" @back="() => $router.go(-1)" />

    <div class="main-content">
      <div class="body">
        <div class="form">
          <a-steps :current="stepsCurrent">
            <a-step
              v-for="item in steps"
              :key="item.title"
              :title="item.title"
              :description="item.description"
            />
          </a-steps>

          <!-- 基础信息-start -->
          <a-form-model
            ref="ruleForm"
            :model="form"
            :rules="rules"
            :label-col="{ span: 7 }"
            :wrapper-col="{ span: 17 }"
            v-show="stepsCurrent === 0"
          >
            <a-form-model-item
              ref="realName"
              label="监工管理名称"
              prop="realName"
            >
              <a-input
                v-model="form.realName"
                placeholder="请输入监工管理名称"
                @blur="
                  () => {
                    $refs.realName.onFieldBlur()
                  }
                "
              />
            </a-form-model-item>
            <a-form-model-item>
              <a-button
                class="submit-btn"
                type="primary"
                :loading="addLoading"
                @click="onAddSubmit"
              >
                下一步
              </a-button>
            </a-form-model-item>
          </a-form-model>
          <!-- 基础信息-end -->
          <!-- 绑定微信号-start -->
          <a-form-model
            :label-col="{ span: 9 }"
            :wrapper-col="{ span: 6 }"
            v-show="stepsCurrent === 1"
          >
            <div class="bind-status" v-if="qrcodeScanStatu">
              <div class="icon-box">
                <a-icon
                  theme="filled"
                  :style="{ color: '#FF4D4F', fontSize: '70px' }"
                  type="info-circle"
                />
              </div>
              <div class="bind-title">已绑定微信，账号待开通</div>
              <div class="bind-p">请在联系公司后台负责人开通账号</div>
              <div class="btn-box">
                <a-button class="bind-btn" @click="$router.go(-2)">
                  返回
                </a-button>
              </div>
            </div>
            <template v-else>
              <div class="qrcode-box">
                <div class="qrcode">
                  <iframe
                    id="iframe"
                    :src="wechatLink"
                    width="300px"
                    height="400px"
                    frameborder="0"
                    name="iframea"
                  ></iframe>
                </div>
              </div>
            </template>
          </a-form-model>
          <!-- 绑定微信号-end -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import NP from 'number-precision'
export default {
  data() {
    return {
      title: '',
      stepsCurrent: 0,
      steps: [
        {
          title: '基础信息',
          description: '基础信息'
        },
        {
          title: '绑定微信号',
          description: '用于中台的账号登录'
        }
      ],
      form: {
        realName: '',
      },
      rules: {
        realName: [
          { required: true, message: '请输入渠道商名称', trigger: 'blur' }
        ],
      },
      addLoading: false,
      wechatLink: '',
      qrcodeScanStatu: false
    }
  },
  created() {
    this.title = this.$getPageTitle()
    const query = this.$route.query
    if (query.code) {
      // 绑定微信
      this.stepsCurrent = 1
      const params = {
        code: query.code,
        // userType: query.userType,
        id: query.id
      }
      this.channelBusinessBindWX(params)
    } else if (query.status) {
      // 绑定成功
      this.stepsCurrent = 1
      this.qrcodeScanStatu = true
    }
  },
  methods: {
    onAddSubmit() {
      // 新增监工管理
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          this.addLoading = true
          const hide = this.$message.loading('请稍后', 0)
          const data = {
            realName: this.form.realName
          }
          this.$axios.getFowllerAdd(data).then((res) => {
            const subData = {
              id: res.data.data.id,
              key: 'fowllerAdd'
            }
            // 获取绑定微信二维码
            this.$axios.getNeedFowller(subData).then((subRes) => {
              this.wechatLink = subRes.data.data.url
              this.stepsCurrent++
              this.addLoading = false
              hide()
            })
          })
        }
      })
    },
    channelBusinessBindWX(data) {
      // 绑定微信
      const hide = this.$message.loading('请稍后', 0)
      this.$axios.getBindFowllerOpenid(data).then(() => {
        hide()
        this.qrcodeScanStatu = true
        this.$router.replace({
          query: {
            status: 1
          }
        })
      })
    }
  }
}
</script>

<style scoped>
.body {
  background-color: #fff;
  padding: 48px 0;
}

.form {
  width: 450px;
  margin: 0 auto;
}

.ant-form {
  margin-top: 40px;
}

.submit-btn {
  margin-left: 9.5em;
}

.qrcode-box {
  height: 400px;
  position: relative;
}

.qrcode {
  position: absolute;
  top: 24px;
  left: 50%;
  transform: translateX(-50%);
}

.bind-status {
  width: 464px;
  margin: 40px auto 0 auto;
}

.bind-status .icon-box {
  width: 70px;
  height: 70px;
  margin: 30px auto;
}

.bind-title {
  text-align: center;
  font-size: 24px;
  line-height: 34px;
  font-weight: bold;
}

.bind-p {
  text-align: center;
  font-size: 14px;
  height: 22px;
  margin: 8px auto 24px auto;
}

.btn-box {
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
